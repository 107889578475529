.loader-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
}

.circle-1 {
  left: 0;
  animation-delay: 0s;
  animation: swap 2s infinite;
  background-color: white;
  border: 2px solid #003d50;
  z-index: 999;
}

.circle-2 {
  left: 20px;
  animation-delay: 0.5s; /* Delay to make the circles swap places alternately */
  animation: swap2 2s infinite;
  background-color: #ea672f;
  border: 2px solid #ea672f;
}

@keyframes swap {
  0%,
  100% {
    left: 0px;
  }
  50% {
    left: 20px;
  }
}

@keyframes swap2 {
  0%,
  100% {
    left: 20px;
  }
  50% {
    left: 0px;
  }
}
