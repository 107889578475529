.landing-page-top {
  margin: 100px;
}
.react-datepicker {
  font-family: "Satoshi-Variable";
  font-weight: 600;
}
.react-datepicker__input-container {
  font-size: 14px;
}
.react-datepicker__input-container input {
  border: 1px solid #d1d1d1;
  font-size: 14px;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: transparent;
}
.react-datepicker__input-container input:disabled {
  background-color: #dadada45;
  color: gray;
}
.react-datepicker__navigation-icon::before {
  color: black;
}
.react-datepicker__header {
  background-color: white;
  border-bottom: 1px solid #aeaeae2e;
}
.quill-container {
  height: 100%;
  font-family: "Satoshi-Variable";
}

.ql-container {
  height: 100%; /* Ensures the container uses the full height */
  border-radius: inherit; /* Matches the parent's border radius */
}

.ql-editor {
  height: 100%; /* Ensures the editor uses the full height */
  border-radius: inherit; /* Matches the parent's border radius */
}

.ql-container.ql-snow .ql-editor::before {
  font-style: normal;
  font-size: 14px;
  color: #d1d1d1;
}
.ql-toolbar .ql-snow {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.ql-container .ql-snow {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
@media (max-width: 767px) {
  .landing-page-top {
    margin: 10px !important;
  }
}
