@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @font-face {
  font-family: "Helvetica";
  src: url("./Assets/fonts/helvetica-now/Helvetica_Now_Regular.otf");
}

@font-face {
  font-family: "Helvetica Bold";
  src: url("./Assets/fonts/helvetica-now/Helvetica_Now_Bold.otf");
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/**
* @license
*
* Font Family: Satoshi
* Designed by: Deni Anggara
* URL: https://www.fontshare.com/fonts/satoshi
* © 2024 Indian Type Foundry
*
* Satoshi Light 
* Satoshi LightItalic 
* Satoshi Regular 
* Satoshi Italic 
* Satoshi Medium 
* Satoshi MediumItalic 
* Satoshi Bold 
* Satoshi BoldItalic 
* Satoshi Black 
* Satoshi BlackItalic 
* Satoshi Variable (Variable font)
* Satoshi VariableItalic (Variable font)

*
*/
@font-face {
  font-family: "Satoshi-Light";
  src: url("../fonts/Satoshi-Light.woff2") format("woff2"),
    url("../fonts/Satoshi-Light.woff") format("woff"),
    url("../fonts/Satoshi-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi-LightItalic";
  src: url("../fonts/Satoshi-LightItalic.woff2") format("woff2"),
    url("../fonts/Satoshi-LightItalic.woff") format("woff"),
    url("../fonts/Satoshi-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Satoshi-Regular";
  src: url("../fonts/Satoshi-Regular.woff2") format("woff2"),
    url("../fonts/Satoshi-Regular.woff") format("woff"),
    url("../fonts/Satoshi-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi-Italic";
  src: url("../fonts/Satoshi-Italic.woff2") format("woff2"),
    url("../fonts/Satoshi-Italic.woff") format("woff"),
    url("../fonts/Satoshi-Italic.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Satoshi-Medium";
  src: url("../fonts/Satoshi-Medium.woff2") format("woff2"),
    url("../fonts/Satoshi-Medium.woff") format("woff"),
    url("../fonts/Satoshi-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi-MediumItalic";
  src: url("../fonts/Satoshi-MediumItalic.woff2") format("woff2"),
    url("../fonts/Satoshi-MediumItalic.woff") format("woff"),
    url("../fonts/Satoshi-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Satoshi-Bold";
  src: url("../fonts/Satoshi-Bold.woff2") format("woff2"),
    url("../fonts/Satoshi-Bold.woff") format("woff"),
    url("../fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi-BoldItalic";
  src: url("../fonts/Satoshi-BoldItalic.woff2") format("woff2"),
    url("../fonts/Satoshi-BoldItalic.woff") format("woff"),
    url("../fonts/Satoshi-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Satoshi-Black";
  src: url("../fonts/Satoshi-Black.woff2") format("woff2"),
    url("../fonts/Satoshi-Black.woff") format("woff"),
    url("../fonts/Satoshi-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi-BlackItalic";
  src: url("../fonts/Satoshi-BlackItalic.woff2") format("woff2"),
    url("../fonts/Satoshi-BlackItalic.woff") format("woff"),
    url("../fonts/Satoshi-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}
/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 900.0;
*
* available axes:
'wght' (range from 300.0 to 900.0
*/
@font-face {
  font-family: "Satoshi-Variable";
  src: url("../fonts/Satoshi-Variable.woff2") format("woff2"),
    url("../fonts/Satoshi-Variable.woff") format("woff"),
    url("../fonts/Satoshi-Variable.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}
/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 900.0;
*
* available axes:
'wght' (range from 300.0 to 900.0
*/
@font-face {
  font-family: "Satoshi-VariableItalic";
  src: url("../fonts/Satoshi-VariableItalic.woff2") format("woff2"),
    url("../fonts/Satoshi-VariableItalic.woff") format("woff"),
    url("../fonts/Satoshi-VariableItalic.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: italic;
}

/* Headings */
.rich-text-container h1 {
  @apply text-2xl font-bold mt-4 mb-3;
}

.rich-text-container h2 {
  @apply text-xl font-bold mt-4 mb-3;
}

.rich-text-container h3 {
  @apply text-xl font-semibold mt-3 mb-2;
}

.rich-text-container h4 {
  @apply text-lg font-semibold mt-3 mb-2;
}

.rich-text-container h5 {
  @apply text-base font-medium mt-2 mb-1;
}

.rich-text-container h6 {
  @apply text-sm font-medium mt-2 mb-1;
}
.rich-text-company-profile p {
  @apply text-lg font-medium;
}
/* Ordered and Unordered Lists */
.rich-text-container ol {
  @apply list-decimal ml-5 mb-4;
}

.rich-text-container ul {
  @apply list-disc ml-5 mb-4;
}

.rich-text-container li {
  @apply mb-2;
}

/* Blockquotes */
.rich-text-container blockquote {
  @apply border-l-4 border-gray-300 pl-4 italic mb-4;
}

/* Code Blocks and Inline Code */
.rich-text-container pre {
  @apply bg-gray-100 p-4 rounded-md mb-4;
}

.rich-text-container code {
  @apply bg-gray-100 px-1 rounded-md text-sm text-red-600;
}

/* Links */
.rich-text-container a {
  @apply text-blue-600 underline;
}

.rich-text-container a:hover {
  @apply text-blue-800;
}

/* Tables */
.rich-text-container table {
  @apply min-w-full divide-y divide-gray-200;
}

.rich-text-container th {
  @apply bg-gray-100 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
}

.rich-text-container td {
  @apply px-6 py-4 whitespace-nowrap text-sm text-gray-700;
}

.rich-text-container thead {
  @apply bg-gray-50;
}

.rich-text-container tbody {
  @apply bg-white divide-y divide-gray-200;
}

/* Images */
.rich-text-container img {
  @apply max-w-full rounded-lg my-4;
}
