.phone-input-container {
  position: relative;
  z-index: 1000; /* Adjust z-index as needed */
  width: 100%;
}
.react-tel-input .country-list {
  position: fixed;
}
.phone-input {
  width: 100%;
}
.react-tel-input .form-control {
  width: 100%;
}
.phone-input-dropdown {
  position: fixed;
  z-index: 9999; /* Ensure the dropdown appears above other elements */
}
